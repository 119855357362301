import convertObjectToQueryParams from '../helpers/convertObjectToQueryParams'

export default function getChangeScopeURL (propertyID) {
  const params = convertObjectToQueryParams({
    original_url: encodeURIComponent(window.location.href),
    current_building_id: propertyID,
  })

  return `/buildings/change_scope${params}`
}
