import Vue from 'vue/dist/vue.esm'
import $ from 'jquery'

import BuildingNavigation from '../components/building_navigation.vue'

$(document).ready(() => {
  if($('#building-navigation-wrapper').length > 0){
    new Vue({
      el: '#building-navigation-wrapper',
      components: { BuildingNavigation },
    })
  }
})
