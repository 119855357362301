<template>
  <div class="property-list-wrapper">
    <ol class="property-list">
      <li
        :data-property-id="property.id"
        :key="property.id"
        v-for="property of properties"
      >
        <a
          :href="getChangeScopeURL(property.id)"
          :title="property.name"
        >
          <div
            class="image property-image"
            :style="{ backgroundImage: `url(${property.image})` }"
          />
          <span>{{property.name}}</span>
        </a>
      </li>
    </ol>
  </div>
</template>

<script>
import buildingColorable from './mixins/building_colorable.js'
import convertObjectToQueryParams from '../helpers/convertObjectToQueryParams'
import getChangeScopeURL from '../helpers/getChangeScopeURL'

export default {
  data () {
    return {
      defaultScopeParams: {
        original_url: window.location.href
      },
    }
  },

  methods: {
    getChangeScopeURL,
  },

  mixins: [buildingColorable],

  props: {
    properties: {
      type: Array,
      required: true,
    },
  },
}
</script>

<style>
@import '../styles/variables/variables.css';
</style>

<style scoped>
@import '../styles/reset.css';
@import '../styles/app.css';
</style>
