export default function sortByProperty (originalArray, property, isCaseSensitive) {
  return [...originalArray].sort((a, b) => {
    const propA = isCaseSensitive ? a[property] : a[property].toLowerCase()
    const propB = isCaseSensitive ? b[property] : b[property].toLowerCase()

    if (propA > propB) {
      return 1
    }

    if (propA < propB) {
      return -1
    }

    return 0
  })
}
