export default {
  props: {
    primary: {
      default: () => '#444867',
      type: String,
      required: false,
    },

    secondary: {
      default: () => '#d7ae4a',
      type: String,
      required: false,
    },
  },

  computed: {
    buildingColorableProps () {
      return {
        primary: this.primary,
        secondary: this.secondary,
      }
    },

    primaryColor () {
      return { color: this.primary }
    },

    forButtons () {
      var color = '#FFFFFF'
      var border = this.secondary
      if (this.secondary.toUpperCase() === '#FFFFFF') {
        color = this.primary
        border = this.primary
      }
      return {
        'background-color': this.secondary,
        'border-color': border,
        'color': color,
      }
    },
  },
}
