<template>
  <div
    id="building-navigation"
    :style="primaryColor"
  >
    <input
      @change="({ target: { checked } }) => this.isVisible = checked"
      hidden
      id="building-navigation-control"
      type="checkbox"
      :checked="isVisible"
    />

    <label for="building-navigation-control">
      View Other Properties
      <i class="fa fa-caret-down" />
    </label>

    <nav>
      <input
        placeholder="Search for property"
        type="search"
        @keyup="onQueryKeyup"
        @input="query = $event.target.value"
        v-if="properties.length > 5"
      />

      <div class="property-lists">
        <div
          class="favorites"
          v-if="favorites.length"
        >
          <header :style="primaryColor">
            Favorites
          </header>

          <property-navigation-list
            :primary="primary"
            :properties="favorites"
          />
        </div>

        <div class="all-properties">
          <header>
            <a
              :href="getChangeScopeURL(null)"
              :style="primaryColor">
              All Properties
            </a>
          </header>

          <property-navigation-list
            :primary="primary"
            :properties="visibleProperties"
          />
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import buildingColorable from './mixins/building_colorable.js'
import convertObjectToQueryParams from '../helpers/convertObjectToQueryParams'
import getChangeScopeURL from '../helpers/getChangeScopeURL'
import PropertyNavigationList from './property_navigation_list.vue'
import sortByProperty from '../helpers/sortByProperty'

export default {
  components: { PropertyNavigationList },

  computed: {
    navigationSearchInput () {
      return document.querySelector('#building-navigation nav input[type=search]')
    },

    favorites () {
      return this.properties.filter(({ id }) => this.favoriteIDs.indexOf(id) !== -1)
    },

    sortedProperties () {
      return sortByProperty(this.properties, 'name', false)
    },

    visibleProperties () {
      const query = this.query.trim().toLowerCase()
      return this.sortedProperties.filter(({ name }) => name.toLowerCase().indexOf(query) !== -1)
    },
  },

  data: () => ({
    isVisible: false,
    query: '',
  }),

  methods: {
    _close () {
      this.isVisible = false
    },

    _createForeignEventListener () {
      document.addEventListener('click', this._onForeignClick)
    },

    _destroyForeignEventListener () {
      document.removeEventListener('click', this._onForeignClick)
    },

    _onForeignClick ({ target }) {
      const controlLabelElement = document.querySelector('[for="building-navigation-control"')
      const navElement = document.querySelector('#building-navigation nav')

      if (!navElement.contains(target) && target !== controlLabelElement) {
        this._close()
      }
    },

    _onKeyDown (event) {
      const { key } = event
      const typableInputTypes = [
        'date',
        'datetime',
        'datetime-local',
        'month',
        'number',
        'password',
        'search',
        'tel',
        'text',
        'time',
        'url',
        'week',
      ].map(type => `input${type && `[type=${type}]`}:focus`).join(', ')
      const currentlyFocusedInput = document.querySelector(`[contenteditable]:focus, [contenteditable] *:focus, textarea:focus, ${typableInputTypes}:focus`)
      const currentlyFocusedInputIsBuildingNavigationSearch = currentlyFocusedInput === this.navigationSearchInput

      if (!this.query && (!currentlyFocusedInput || currentlyFocusedInputIsBuildingNavigationSearch) && (key === '/')) {
        if (!this.isVisible) {
          this._open()
        } else {
          this._close()
        }
      }

      if (this.isVisible && ['esc', 'escape'].includes(key.toLowerCase())) {
        this._close()
      }
    },

    _open () {
      this.isVisible = true
    },

    getChangeScopeURL,

    onControlChange ({ target: { checked } }) {
      this.isVisible = checked
    },

    onQueryKeyup (event) {
      if (event.key.toLowerCase() === 'enter' && this.visibleProperties.length === 1) {
        window.location = getChangeScopeURL(this.visibleProperties[0].id)
      }
    },
  },

  mixins: [buildingColorable],

  props: {
    favoriteIDs: {
      default: () => [],
      type: Array,
      required: false,
    },
    properties: {
      type: Array,
      required: true,
    },
  },

  watch: {
    isVisible () {
      if (this.isVisible) {
        this._createForeignEventListener()

        // We have to focus on the next tick because the new `isVisible` value
        // won't affect the UI until the end of this tick. Changing `isVisible`
        // will cause a reflow/repaint, but trying to focus the input before
        // that reflow/repaint occurs won't work because the input's ancestor
        // will still be `display: none`.
        setTimeout(() => this.navigationSearchInput.focus(), 0)
      } else {
        this._destroyForeignEventListener()
      }
    },
  },

  /***************************************************************************\
    Lifecycle Methods
  \***************************************************************************/

  beforeDestroy () {
    document.removeEventListener('keydown', this._onKeyDown)
  },

  mounted () {
    document.addEventListener('keydown', this._onKeyDown)
  },
}
</script>

<style>
@import '../styles/variables/variables.css';
</style>

<style scoped>
@import '../styles/reset.css';
@import '../styles/app.css';
</style>
